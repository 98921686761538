// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes Text_fadeOut__QPzVB {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.Text_container__VNNx4 {
  padding: 48px 0;
  text-align: center;
  font-weight: 600;
  animation: Text_fadeOut__QPzVB 1s;
}`, "",{"version":3,"sources":["webpack://./src/components/MyServices/List/Text/Text.animations.scss","webpack://./src/components/MyServices/List/Text/Text.module.scss"],"names":[],"mappings":"AAAA;EACE;IACE,UAAA;ECCF;EDCA;IACE,UAAA;ECCF;AACF;AALA;EACE,eAAA;EACA,kBAAA;EACA,gBAAA;EAEA,iCAAA;AAMF","sourcesContent":["@keyframes fadeOut {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n","@use \"./Text.animations\";\n\n.container {\n  padding: 48px 0;\n  text-align: center;\n  font-weight: 600;\n\n  animation: fadeOut 1s;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Text_container__VNNx4`,
	"fadeOut": `Text_fadeOut__QPzVB`
};
export default ___CSS_LOADER_EXPORT___;
