// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SkillsSubList_container__HE48f .SkillsSubList_item__w-tFs {
  margin-bottom: 18px;
}
.SkillsSubList_container__HE48f .SkillsSubList_item__w-tFs:last-child {
  margin-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/MySkills/SkillsSections/SkillSection/SkillsSubList/SkillsSubList.module.scss"],"names":[],"mappings":"AACE;EACE,mBAAA;AAAJ;AAEI;EACE,gBAAA;AAAN","sourcesContent":[".container {\n  .item {\n    margin-bottom: 18px;\n\n    &:last-child {\n      margin-bottom: 0;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SkillsSubList_container__HE48f`,
	"item": `SkillsSubList_item__w-tFs`
};
export default ___CSS_LOADER_EXPORT___;
