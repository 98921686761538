// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 768px) {
  .Slider_container__KJfSx {
    display: none;
  }
}
.Slider_container__KJfSx .Slider_sliderContainer__ZbBFR {
  height: 455px;
}
.Slider_container__KJfSx .Slider_sliderContainer__ZbBFR .Slider_slider__pnJWO {
  position: absolute;
  width: 100vw;
  left: 0;
  padding: 0 calc((100vw - 298px) / 2) 40px;
}
.Slider_container__KJfSx .Slider_description__ro419 {
  margin-top: 36px;
}`, "",{"version":3,"sources":["webpack://./src/mixins.scss","webpack://./src/components/MyExperience/Slider/Slider.module.scss"],"names":[],"mappings":"AAGE;ECAF;IAEI,aAAA;EAFF;AACF;AAIE;EACE,aAAA;AAFJ;AAII;EACE,kBAAA;EACA,YAAA;EACA,OAAA;EAGA,yCAAA;AAJN;AAQE;EACE,gBAAA;AANJ","sourcesContent":["@use \"/src/constants.scss\";\n\n@mixin responsive($query) {\n  @media ( min-width: $query ) {\n    @content;\n  }\n}\n\n@mixin ifLess($query) {\n  @media ( max-width: $query ) {\n    @content;\n  }\n}\n\n@mixin forMiddleScreen() {\n  @media (min-width: constants.$screen-medium ) and (max-width: constants.$screen-big) {\n    @content;\n  }\n}","@use \"/src/constants\";\n@use \"/src/mixins\";\n\n.container {\n  @include mixins.responsive(constants.$screen-medium) {\n    display: none;\n  }\n\n  .sliderContainer {\n    height: 455px;\n\n    .slider {\n      position: absolute;\n      width: 100vw;\n      left: 0;\n\n      $padding: calc((100vw - 298px) / 2);\n      padding: 0 $padding 40px;\n    }\n  }\n\n  .description {\n    margin-top: 36px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Slider_container__KJfSx`,
	"sliderContainer": `Slider_sliderContainer__ZbBFR`,
	"slider": `Slider_slider__pnJWO`,
	"description": `Slider_description__ro419`
};
export default ___CSS_LOADER_EXPORT___;
