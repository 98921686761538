// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CurrentPage_currentPage__jeGOy {
  color: #AFE571;
  height: 16px;
  display: flex;
  align-items: center;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/components/Header/NavigationMenu/CurrentPage/CurrentPage.module.scss","webpack://./src/constants.scss"],"names":[],"mappings":"AAEA;EACE,cCFkB;EDGlB,YAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;AADF","sourcesContent":["@use \"/src/constants\" as constants;\n\n.currentPage {\n  color: constants.$color-light-green;\n  height: 16px;\n  display: flex;\n  align-items: center;\n  font-weight: 500;\n}","// colors\n$color-light-green: #AFE571;\n$color-dark-blue: #233F4C;\n$color-light-blue: #3C687D;\n$color-error: #FF4949;\n\n// desktop default fonts\n$font-default-desktop-h1: 700 44px \"Gilroy\";\n$font-default-desktop-h2: 600 38px \"Gilroy\";\n$font-default-desktop-h3: 600 28px \"Gilroy\";\n$font-default-desktop-h4: 600 28px \"Gilroy\";\n$font-default-desktop-p: 400 18px \"Gilroy\";\n\n// desktop default line heights\n$font-line-height-desktop-h1: 50px;\n$font-line-height-desktop-h2: 50px;\n$font-line-height-desktop-h3: 28px;\n$font-line-height-desktop-h4: 50px;\n$font-line-height-desktop-p: 26px;\n\n// mobile default fonts\n$font-default-mobile-h1: 600 26px \"Gilroy\";\n$font-default-mobile-h2: 600 26px \"Gilroy\";\n$font-default-mobile-h3: 600 26px \"Gilroy\";\n$font-default-mobile-h4: 600 20px \"Gilroy\";\n$font-default-mobile-p: 400 16px \"Gilroy\";\n\n// mobile default line heights\n$font-line-height-mobile-h1: 34px;\n$font-line-height-mobile-h2: 34px;\n$font-line-height-mobile-h3: 26px;\n$font-line-height-mobile-h4: 20px;\n$font-line-height-mobile-p: 26px;\n\n// breakpoints\n$screen-medium: 768px;\n$screen-big: 1279px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"currentPage": `CurrentPage_currentPage__jeGOy`
};
export default ___CSS_LOADER_EXPORT___;
