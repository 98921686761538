// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes Description_fadeIn__4RoGE {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes Description_fadeOut__s4bGQ {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.Description_container__RsC0y {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Description_container__RsC0y .Description_text__t4Q6H {
  height: 180px;
  width: 314px;
  text-align: justify;
  font-weight: 600;
  animation: Description_fadeIn__4RoGE 1s ease-in-out;
}`, "",{"version":3,"sources":["webpack://./src/components/MyServices/Slider/Description/Description.animations.scss","webpack://./src/components/MyServices/Slider/Description/Description.module.scss"],"names":[],"mappings":"AAAA;EACE;IACE,UAAA;ECCF;EDCA;IACE,UAAA;ECCF;AACF;ADEA;EACE;IACE,UAAA;ECAF;EDEA;IACE,UAAA;ECAF;AACF;AAZA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AAcF;AAXE;EACE,aAAA;EACA,YAAA;EACA,mBAAA;EACA,gBAAA;EAEA,mDAAA;AAYJ","sourcesContent":["@keyframes fadeIn {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n\n@keyframes fadeOut {\n  from {\n    opacity: 1;\n  }\n  to {\n    opacity: 0;\n  }\n}\n","@use \"/src/constants\";\n@use \"Description.animations\";\n\n.container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n\n  .text {\n    height: 180px;\n    width: 314px;\n    text-align: justify;\n    font-weight: 600;\n\n    animation: fadeIn 1s ease-in-out;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Description_container__RsC0y`,
	"text": `Description_text__t4Q6H`,
	"fadeIn": `Description_fadeIn__4RoGE`,
	"fadeOut": `Description_fadeOut__s4bGQ`
};
export default ___CSS_LOADER_EXPORT___;
