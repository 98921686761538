// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Contacts_container__fB7JB {
  display: flex;
  flex-direction: column;
  align-content: center;
  flex-wrap: wrap;
}`, "",{"version":3,"sources":["webpack://./src/components/Contacts/Contacts.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,qBAAA;EACA,eAAA;AACF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  align-content: center;\n  flex-wrap: wrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Contacts_container__fB7JB`
};
export default ___CSS_LOADER_EXPORT___;
