// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SkillItem_container__WDqY- {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  font-family: "Cascadia Code";
  font-weight: 600;
  cursor: pointer;
  transition-duration: 0.5s;
}
.SkillItem_container__WDqY-:hover {
  background-color: rgba(255, 255, 255, 0.1);
}`, "",{"version":3,"sources":["webpack://./src/components/MySkills/SkillsSections/SkillSection/SkillsSubList/SkillsItem/SkillItem.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,2CAAA;EACA,mBAAA;EACA,4BAAA;EACA,gBAAA;EACA,eAAA;EACA,yBAAA;AACF;AACE;EACE,0CAAA;AACJ","sourcesContent":[".container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 52px;\n  background-color: rgba(255, 255, 255, 0.05);\n  border-radius: 12px;\n  font-family: \"Cascadia Code\";\n  font-weight: 600;\n  cursor: pointer;\n  transition-duration: 0.5s;\n\n  &:hover {\n    background-color: rgba(255, 255, 255, 0.1);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SkillItem_container__WDqY-`
};
export default ___CSS_LOADER_EXPORT___;
