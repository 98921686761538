// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Copyright_copyright__Wfc0p {
  font-family: "Lufga";
  line-height: 20px;
  opacity: 0.5;
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/Copyright/Copyright.module.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,iBAAA;EACA,YAAA;AACF","sourcesContent":[".copyright {\n  font-family: \"Lufga\";\n  line-height: 20px;\n  opacity: 0.5;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"copyright": `Copyright_copyright__Wfc0p`
};
export default ___CSS_LOADER_EXPORT___;
