// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Title_title__ZnFt1 {
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/WhoIAm/AboutMe/Title/Title.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF","sourcesContent":[".title {\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `Title_title__ZnFt1`
};
export default ___CSS_LOADER_EXPORT___;
