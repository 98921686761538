// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Arrow_arrow__WDWkx {
  display: none;
  width: 32px;
  height: 32px;
}
@media (min-width: 768px) {
  .Arrow_arrow__WDWkx {
    display: block;
  }
}
.Arrow_arrow__WDWkx.Arrow_active__LdAQd {
  cursor: pointer;
  transition-duration: 0.1s;
}
.Arrow_arrow__WDWkx.Arrow_active__LdAQd:hover {
  opacity: 0.7;
}`, "",{"version":3,"sources":["webpack://./src/components/WhoIAm/AboutMe/Slider/Arrow/Arrow.module.scss","webpack://./src/mixins.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,WAAA;EACA,YAAA;AAFF;ACDE;EDAF;IAMI,cAAA;EADF;AACF;AAGE;EACE,eAAA;EACA,yBAAA;AADJ;AAGI;EACE,YAAA;AADN","sourcesContent":["@use \"/src/constants\";\n@use \"/src/mixins\";\n\n.arrow {\n  display: none;\n  width: 32px;\n  height: 32px;\n\n  @include mixins.responsive(constants.$screen-medium) {\n    display: block;\n  }\n\n  &.active {\n    cursor: pointer;\n    transition-duration: 0.1s;\n\n    &:hover {\n      opacity: 0.7;\n    }\n  }\n}","@use \"/src/constants.scss\";\n\n@mixin responsive($query) {\n  @media ( min-width: $query ) {\n    @content;\n  }\n}\n\n@mixin ifLess($query) {\n  @media ( max-width: $query ) {\n    @content;\n  }\n}\n\n@mixin forMiddleScreen() {\n  @media (min-width: constants.$screen-medium ) and (max-width: constants.$screen-big) {\n    @content;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"arrow": `Arrow_arrow__WDWkx`,
	"active": `Arrow_active__LdAQd`
};
export default ___CSS_LOADER_EXPORT___;
