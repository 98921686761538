// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes Description_fadeIn__BEee4 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes Description_fadeOut__si7QS {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.Description_container__XNrG0 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Description_container__XNrG0 .Description_title__juYTr {
  text-align: center;
}
.Description_container__XNrG0 .Description_text__N1MjE {
  height: 302px;
  overflow-y: auto;
  margin-top: 12px;
  width: 314px;
  text-align: justify;
  padding-right: 10px;
  animation: Description_fadeIn__BEee4 1s ease-in-out;
}`, "",{"version":3,"sources":["webpack://./src/components/MyExperience/Slider/Description/Description.animations.scss","webpack://./src/components/MyExperience/Slider/Description/Description.module.scss"],"names":[],"mappings":"AAAA;EACE;IACE,UAAA;ECCF;EDCA;IACE,UAAA;ECCF;AACF;ADEA;EACE;IACE,UAAA;ECAF;EDEA;IACE,UAAA;ECAF;AACF;AAZA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AAcF;AAZE;EACE,kBAAA;AAcJ;AAXE;EACE,aAAA;EACA,gBAAA;EACA,gBAAA;EACA,YAAA;EACA,mBAAA;EAEA,mBAAA;EACA,mDAAA;AAYJ","sourcesContent":["@keyframes fadeIn {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n\n@keyframes fadeOut {\n  from {\n    opacity: 1;\n  }\n  to {\n    opacity: 0;\n  }\n}\n","@use \"/src/constants\";\n@use \"./Description.animations\";\n\n.container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n  .title {\n    text-align: center;\n  }\n\n  .text {\n    height: 302px;\n    overflow-y: auto;\n    margin-top: 12px;\n    width: 314px;\n    text-align: justify;\n\n    padding-right: 10px;\n    animation: fadeIn 1s ease-in-out;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Description_container__XNrG0`,
	"title": `Description_title__juYTr`,
	"text": `Description_text__N1MjE`,
	"fadeIn": `Description_fadeIn__BEee4`,
	"fadeOut": `Description_fadeOut__si7QS`
};
export default ___CSS_LOADER_EXPORT___;
