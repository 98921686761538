// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Slider_container__Erocn {
  display: flex;
  align-items: center;
}
@media (min-width: 768px) {
  .Slider_container__Erocn {
    width: 444px;
  }
}
.Slider_container__Erocn .Slider_slider__75VKY {
  width: 276px;
  height: 184px;
}
@media (min-width: 768px) {
  .Slider_container__Erocn .Slider_slider__75VKY {
    width: 310px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/WhoIAm/AboutMe/Slider/Slider.module.scss","webpack://./src/mixins.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,mBAAA;AAFF;ACAE;EDAF;IAII,YAAA;EAAF;AACF;AAEE;EACE,YAAA;EACA,aAAA;AAAJ;ACTE;EDOA;IAKI,YAAA;EACJ;AACF","sourcesContent":["@use \"/src/constants\";\n@use \"/src/mixins\";\n\n.container {\n  display: flex;\n  align-items: center;\n  @include mixins.responsive(constants.$screen-medium) {\n    width: 444px;\n  }\n\n  .slider {\n    width: 276px;\n    height: 184px;\n\n    @include mixins.responsive(constants.$screen-medium) {\n      width: 310px;\n    }\n  }\n}\n","@use \"/src/constants.scss\";\n\n@mixin responsive($query) {\n  @media ( min-width: $query ) {\n    @content;\n  }\n}\n\n@mixin ifLess($query) {\n  @media ( max-width: $query ) {\n    @content;\n  }\n}\n\n@mixin forMiddleScreen() {\n  @media (min-width: constants.$screen-medium ) and (max-width: constants.$screen-big) {\n    @content;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Slider_container__Erocn`,
	"slider": `Slider_slider__75VKY`
};
export default ___CSS_LOADER_EXPORT___;
