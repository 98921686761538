// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MyServices_container__EYmuV {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.MyServices_container__EYmuV .MyServices_content__fH8Iv {
  margin-top: 36px;
}
@media (min-width: 768px) {
  .MyServices_container__EYmuV .MyServices_content__fH8Iv {
    margin-top: 78px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/MyServices/MyServices.module.scss","webpack://./src/mixins.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AAFF;AAIE;EACE,gBAAA;AAFJ;ACJE;EDKA;IAII,gBAAA;EADJ;AACF","sourcesContent":["@use \"/src/constants\";\n@use \"/src/mixins\";\n\n.container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n  .content {\n    margin-top: 36px;\n\n    @include mixins.responsive(constants.$screen-medium) {\n      margin-top: 78px;\n    }\n  }\n}\n","@use \"/src/constants.scss\";\n\n@mixin responsive($query) {\n  @media ( min-width: $query ) {\n    @content;\n  }\n}\n\n@mixin ifLess($query) {\n  @media ( max-width: $query ) {\n    @content;\n  }\n}\n\n@mixin forMiddleScreen() {\n  @media (min-width: constants.$screen-medium ) and (max-width: constants.$screen-big) {\n    @content;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `MyServices_container__EYmuV`,
	"content": `MyServices_content__fH8Iv`
};
export default ___CSS_LOADER_EXPORT___;
