// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes NavMenuItem_navMenuMouseOut__mLjQw {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
.NavMenuItem_link__2N7ry.NavMenuItem_prioritier__tMLAi {
  position: relative;
  text-decoration: none;
  color: white;
  height: 16px;
  font-weight: 500;
  animation: NavMenuItem_navMenuMouseOut__mLjQw 0.5s;
}
.NavMenuItem_link__2N7ry.NavMenuItem_prioritier__tMLAi:hover {
  animation-duration: 0s;
  opacity: 1;
}
.NavMenuItem_link__2N7ry.NavMenuItem_prioritier__tMLAi:active {
  opacity: 0.7;
}`, "",{"version":3,"sources":["webpack://./src/components/Header/NavigationMenu/NavMenuItem/NavigationMenuItem.animations.scss","webpack://./src/components/Header/NavigationMenu/NavMenuItem/NavMenuItem.module.scss"],"names":[],"mappings":"AAAA;EACE;IACE,YAAA;ECCF;EDCA;IACE,UAAA;ECCF;AACF;AALA;EACE,kBAAA;EACA,qBAAA;EACA,YAAA;EACA,YAAA;EACA,gBAAA;EAEA,kDAAA;AAMF;AAJE;EACE,sBAAA;EACA,UAAA;AAMJ;AAHE;EACE,YAAA;AAKJ","sourcesContent":["@keyframes navMenuMouseOut {\n  0% {\n    opacity: 0.4;\n  }\n  100% {\n    opacity: 1;\n  }\n}","@use \"NavigationMenuItem.animations\";\n\n.link.prioritier {\n  position: relative;\n  text-decoration: none;\n  color: white;\n  height: 16px;\n  font-weight: 500;\n\n  animation: navMenuMouseOut 0.5s;\n\n  &:hover {\n    animation-duration: 0s;\n    opacity: 1;\n  }\n\n  &:active {\n    opacity: 0.7;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": `NavMenuItem_link__2N7ry`,
	"prioritier": `NavMenuItem_prioritier__tMLAi`,
	"navMenuMouseOut": `NavMenuItem_navMenuMouseOut__mLjQw`
};
export default ___CSS_LOADER_EXPORT___;
