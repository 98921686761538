// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer_footer__PxxIj {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 92px auto 58px;
}
@media (min-width: 768px) {
  .Footer_footer__PxxIj {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 162px;
  }
}
.Footer_footer__PxxIj .Footer_copyright__2WB24 {
  margin-top: 22px;
}
@media (min-width: 768px) {
  .Footer_footer__PxxIj .Footer_copyright__2WB24 {
    margin-top: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.module.scss","webpack://./src/mixins.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EAEA,sBAAA;AAHF;ACFE;EDAF;IAQI,mBAAA;IACA,8BAAA;IACA,iBAAA;EAFF;AACF;AAIE;EACE,gBAAA;AAFJ;ACZE;EDaA;IAII,aAAA;EADJ;AACF","sourcesContent":["@use \"/src/constants\";\n@use \"/src/mixins\";\n\n.footer {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n  margin: 92px auto 58px;\n\n  @include mixins.responsive(constants.$screen-medium) {\n    flex-direction: row;\n    justify-content: space-between;\n    margin-top: 162px;\n  }\n\n  .copyright {\n    margin-top: 22px;\n\n    @include mixins.responsive(constants.$screen-medium) {\n      margin-top: 0;\n    }\n  }\n}","@use \"/src/constants.scss\";\n\n@mixin responsive($query) {\n  @media ( min-width: $query ) {\n    @content;\n  }\n}\n\n@mixin ifLess($query) {\n  @media ( max-width: $query ) {\n    @content;\n  }\n}\n\n@mixin forMiddleScreen() {\n  @media (min-width: constants.$screen-medium ) and (max-width: constants.$screen-big) {\n    @content;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `Footer_footer__PxxIj`,
	"copyright": `Footer_copyright__2WB24`
};
export default ___CSS_LOADER_EXPORT___;
